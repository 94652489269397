require('./frontend.scss');

import "./image/favicon/android-chrome-192x192.png";
import "./image/favicon/apple-touch-icon.png";
import "./image/favicon/favicon-32x32.png";
import "./image/favicon/favicon-16x16.png";
import "./image/favicon/mstile-150x150.png";
import "./image/favicon/site.webmanifest";
import "./image/favicon/safari-pinned-tab.svg";

// Logos
import "./image/background_img.png";
import "./image/logo.svg";
import "./image/logo_white.svg";

import "./image/logos/alliance_logo.svg";
import "./image/logos/approved_workforce_logo.svg";
import "./image/logos/lester_goddard_grey.svg";


// Socials
import "./image/icons/facebook.svg";
import "./image/icons/linkedin.svg";
import "./image/icons/twitter.svg";

// Clients
import "./image/clients/sams.svg";
import "./image/clients/constructionline.svg";
import "./image/clients/chas.svg";
import "./image/clients/safeconstruction.svg";
import "./image/clients/nas.svg";

import "./image/recruitment/experts_in_recruitment.png";
import "./image/recruitment/recruitment.svg";

import "./image/manufacturing-banner.png";
import "./image/engineering-banner.png";

var $ = require("jquery");

function smoothScroll(sel) {
	$(sel).click(function (e) {
		var target = $(this.hash);
		if (target.length) {
			e.preventDefault();
			$('html, body').animate({ scrollTop: target.offset().top }, 500);
		}
	});
}

$(function () {

	smoothScroll('.js-smooth');
	var noHashURL = window.location.href.replace(/#.*$/, '');
	window.history.replaceState('', document.title, noHashURL);

	$('.js-toggle-dropdown').click(function () {
		// TODO Come back to this, not the best way and the html is a mess.
		if ($(this).closest('.accordion').hasClass('active')) {
			$(this).closest('.accordion').toggleClass('active');
			$(this).closest('.accordion').next().hide();
		} else {
			$('.js-toggle-dropdown').parent().parent().next().hide();
			$('.accordion.active').removeClass('active');
			$(this).closest('.accordion').next().show();
			$(this).closest('.accordion').addClass('active');
		}
	});
});